<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Робота із тарифами будинковими
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-2">
            <v-row>
              <v-col cols="12" md="12">
                <v-select :items="services"
                          v-model="service_id"
                          label="Послуга"
                          required
                          :class="!service_id ? 'req-star' : ''"
                          filled
                          hide-details
                          color="grey"
                          @change="getBuildingFillData"
                />
              </v-col>
              <v-col cols="12" md="4">
                <date-component
                    v-model="change_date" req
                    label="Дата зміни/створення"
                    :class_="!change_date ? 'req-star' : ''"
                    @afterSaveDate="getBuildingFillData"
                />
              </v-col>
              <v-col cols="12" md="8">
                <AddressElementSelect v-model="building"
                                      label="Будинок(-ки)" filled req
                                      select_type="building"
                                      @selectChanged="onBuildingChange"
                />
              </v-col>
              <v-col>
                <v-tabs class="custom-tabs" color="success">
                  <v-tab class="text-left justify-start">
                    Табличні дані
                  </v-tab>
                  <v-tab-item :disabled="!header_ready">
                    <div class="form-table-custom mt-4" style="min-height: 120px">
                      <div class="table-command-panel mt-3 mb-3">
                        <v-btn plain small class="grey lighten-3 mr-2"
                               :disabled="!header_ready"
                               @click="onTariffAddRow">
                          <v-icon small color="success" left>mdi-plus</v-icon>
                          Додати
                        </v-btn>
                        <v-btn plain small class="grey lighten-3"
                               :disabled="!tariff_table.length"
                               @click="onTariffDelete"
                        >
                          <v-icon small color="error" left>mdi-delete</v-icon>
                          Вилучити
                        </v-btn>
                      </div>
                      <table>
                        <thead>
                        <tr>
                          <th rowspan="2" style="width: 30px;"></th>
                          <th rowspan="2" style="min-width: 70px; width: 70px !important;">№ п/п</th>
                          <th rowspan="2" style="min-width: 260px; width: 260px !important;">Група тарифу</th>
                          <th rowspan="1" colspan="3">
                            Тариф
                          </th>
                        </tr>
                        <tr>
                          <th>
                            Було
                          </th>
                          <th>
                            Стало
                          </th>
                          <th>
                            Різниця, %
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, idx) in tariff_table" :key="`tariff-${idx}`">
                          <td style="width: 30px">
                            <v-checkbox hide-details class="ml-2 mt-0" :ripple="false" color="secondary"
                                        :disabled="!header_ready"
                                        :value="selected_tariff.includes(item)"
                                        @change="onSelectTariff(item)"/>
                          </td>
                          <td style="min-width: 70px; width: 70px !important;">
                            <input type="text" v-integer disabled :value="idx + 1">
                          </td>
                          <td style="min-width: 260px; width: 260px !important;">
                            <v-select eager hide-details single-line solo
                                      flat
                                      :items="tariff_groups_select"
                                      v-model="item.tariff_group_type_id"
                                      class="fill-select-table-input mt-1"
                                      dense
                                      :disabled="!header_ready"
                                      clearable
                                      placeholder="Без групи"
                                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                      @change="onTariffGroupType(item)"
                            />
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-decimal v-model.number="item.tariff_old"
                                   disabled
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-decimal
                                   v-model.number="item.tariff_new"
                                   :class="!item.tariff_new ? 'input-error' : ''"
                                   :disabled="!header_ready"
                            >
                          </td>
                          <td style="min-width: 70px;">
                            <input type="text"
                                   v-decimal
                                   :value="getDifference(item)"
                                   :disabled="true"
                                   style="font-weight: 500 !important;"
                                   :class="[
                                              {'error--text': getDifference(item) < 0},
                                              {'success--text': getDifference(item) > 0}
                                          ]"
                            >
                          </td>
                        </tr>
                        <tr v-if="!tariff_table.length">
                          <td colspan="6"
                              class="success--text"
                              style="text-align: center; padding: 34px 4px !important; font-size: .94rem; font-weight: 500;"
                          >
                            Таблиця порожня. Спершу додайте рядки)
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="table-footer-panel">
                        <v-spacer></v-spacer>
                        <v-btn class="grey lighten-3"
                               :disabled="!documentReady()"
                               @click="crud_operation(1)"
                               :loading="loading"
                        >
                          <v-icon class="mr-2" color="success">mdi-content-save-check-outline</v-icon>
                          Виконати
                        </v-btn>
                      </div>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import fillTablesAPI from '@/utils/axios/fill_tables'
import {ALERT_SHOW} from "@/store/actions/alert";
import tariffGroup from "@/utils/axios/tariff_group"
import {mapGetters} from "vuex";

export default {
  name: "FillTariffByBuilding",
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll'
    }),
    header_ready() {
      return !!(this.building && this.service_id && this.change_date)
    }
  },
  data() {
    return {
      service_id: null,
      building: null,
      change_date: null,
      tariff_table: [],
      selected_tariff: [],
      tariff_groups_select: [],
      loading: false
    }
  },
  methods: {
    onTariffGroupType(payload) {
      const error = this.tariff_table.filter(item =>
          item.tariff_group_type_id === payload.tariff_group_type_id && item !== payload).length

      if (error) {
        payload.tariff_group_type_id = null
        this.$store.dispatch(ALERT_SHOW, {text: 'Така група тарифу вже пристуня у таблиці',
          color: 'error lighten-1'})
      }
    },
    getDifference(payload) {
      return (((payload.tariff_new / (payload.tariff_old || payload.tariff_new)) * 100 - 100) || 0).toFixed(2)
    },
    getBuildingFillData(clear=false) {
      const payload = {
        building_id: this.building,
        service_id: this.service_id,
        change_date: this.change_date
      }

      if (!this.header_ready) return
      if (clear) this.tariff_table = []

      fillTablesAPI.change_building_tariff_get_data(payload)
          .then(response => response.data)
          .then(data => {
            data.forEach(item => {
              const row = this.tariff_table.find(r => r.id === item.id)
              if (!row) {
                this.tariff_table.push(
                    {
                      id: item.id,
                      tariff_group_type_id: item.tariff_group_type_id,
                      tariff_old: item.tariff
                    }
                )
              } else {
                row.tariff_old = item.tariff
                row.tariff_group_type_id = item.tariff_group_type_id
              }
            })
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          });
    },
    documentReady(table = 'tariff') {
      const check = {}
      if (!this.building) return false
      if (!this.service_id) return false
      if (!this.change_date) return false
      if (table === 'tariff') {
        this.tariff_table.forEach(item => {
          if (check[item.tariff_group_type_id || 0] === undefined) {
            check[item.tariff_group_type_id || 0] = 0
          } else {
            check[item.tariff_group_type_id || 0] += 1
          }
        })
        Object.keys(check).forEach(key => {
          const value = check[key]
          if (value > 1) return false
        })
        return !this.tariff_table.filter(item => !item.tariff_new).length
      }
    },
    onTariffAddRow() {
      this.tariff_table.push(
          {
            id: null,
            tariff_group_type_id: null,
            tariff_old: 0,
            tariff_new: 0
          }
      )
    },
    onSelectTariff(payload) {
      if (this.selected_tariff.includes(payload)) {
        this.selected_tariff.splice(this.selected_tariff.indexOf(payload), 1)
      } else {
        this.selected_tariff.push(payload)
      }
    },
    onTariffDelete() {
      if (!this.selected_tariff.length) return
      this.selected_tariff.forEach(item => {
        this.tariff_table.splice(this.tariff_table.indexOf(item), 1)
      })
      this.selected_tariff = []
    },
    onBuildingChange() {
      this.getBuildingFillData()
    },
    crud_operation() {
      if (this.documentReady()) {
        this.loading = true

        const payload = {
          building_id: this.building,
          service_id: this.service_id,
          change_date: this.change_date,
          rows: this.tariff_table
        }

        payload.rows = payload.rows.map(item => {
          return {id: item.id, tariff_group_type_id: item.tariff_group_type_id, tariff: item.tariff_new}
        })

        fillTablesAPI.change_building_tariff_change_data(payload)
            .then(response => response.data)
            .then(() => {
              this.$store.dispatch(ALERT_SHOW, {text: "Дані успішно збережені", color: 'success'})
              this.getBuildingFillData(true)
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.loading = false
            })
      }
    }
  },
  created() {
    tariffGroup.tariff_group_type_select()
        .then(response => response.data)
        .then(data => {
          this.tariff_groups_select = data
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
        })
  }
}
</script>

<style scoped>

</style>
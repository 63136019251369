import { axios } from '@/utils/axios';

const RESOURCE_NAME  = '/fill';

export default {
    fill_service(payload) {
        return axios.post(`${RESOURCE_NAME}/service`, payload)
    },
    fill_table_and_send_sms(payload) {
        return axios.post(`${RESOURCE_NAME}/table-and-send-sms`, payload)
    },
    fill_postal_index(payload) {
        return axios.post(`${RESOURCE_NAME}/postal-index`, payload)
    },
    fill_tariff_group_type(payload) {
        return axios.post(`${RESOURCE_NAME}/tariff-group`, payload)
    },
    fill_checker(payload) {
        return axios.post(`${RESOURCE_NAME}/checker`, payload)
    },
    fill_entrance_and_floor(payload) {
        return axios.post(`${RESOURCE_NAME}/entrance-and-floor`, payload)
    },
    change_building_tariff_get_data(payload) {
        return axios.post(`${RESOURCE_NAME}/change-building-tariff/get-data`, payload)
    },
    change_building_tariff_change_data(payload) {
        return axios.post(`${RESOURCE_NAME}/change-building-tariff/change`, payload)
    },
    fill_data_table(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-data-table`, payload)
    },
    change_data(payload) {
        return axios.post(`${RESOURCE_NAME}/fill-data-change`, payload)
    },
    create_flats_get_max_person_id() {
        return axios.post(`${RESOURCE_NAME}/create-flats/get-max-person-id`)
    },
    create_flats_get_max_flat_number(payload) {
        return axios.post(`${RESOURCE_NAME}/create-flats/get-max-flat-number`, payload)
    },
    create_flats_get_building_by_address(payload) {
        return axios.post(`${RESOURCE_NAME}/create-flats/get-building-by-number`, payload)
    },
    create_flats(payload) {
        return axios.post(`${RESOURCE_NAME}/create-flats`, payload)
    },
    import_social_query(payload) {
        return axios.post(`${RESOURCE_NAME}/import-social-query`, payload, { headers: { 'Content-Type': 'multipart/form-data' } , responseType: 'blob'})
    },
    pension_fund(payload) {
        return axios.post(`${RESOURCE_NAME}/pension-fund`, payload, {responseType: 'blob'})
    }
};